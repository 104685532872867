import { FC, ReactElement } from 'react';

import Breadcrumbs, { Breadcrumb } from '../../../../../compositions/Breadcrumbs/Breadcrumbs';
import { userHasRole } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { Candidate } from '../../../../../models/Candidate/Candidate';
import { DevelopmentPlanWithEvaluation } from '../../../../../models/DevelopmentPlan/DevelopmentPlan';
import { User } from '../../../../../models/User/User';
import { RoutePaths } from '../../../../../Routes';
import { UserRole } from '../../../../../types';

interface DevelopmentPlanEvaluationBreadcrumbsProps {
    user: User;
    candidate: Candidate;
    developmentPlan: DevelopmentPlanWithEvaluation;
    className?: string;
}

const DevelopmentPlanEvaluationBreadcrumbs: FC<DevelopmentPlanEvaluationBreadcrumbsProps> = ({
    user,
    candidate,
    developmentPlan,
    className = '',
}): ReactElement => {
    const crumbs: Breadcrumb[] = userHasRole(UserRole.counselor, user.roles) ? ([
        {
            label: trans('routes.dashboard'),
            to: RoutePaths.dashboard(),
        },
        {
            label: candidate.fullName || '',
            to: RoutePaths.candidateDashboard(candidate.uuid),
        },
        {
            label: trans('connectors.connectedDevelopmentPlanBreadcrumbs.development.counselor'),
            to: RoutePaths.development(candidate.uuid),
        },
        {
            label: developmentPlan.title,
            disabled: true,
        },
        {
            label: trans('common.evaluation'),
            disabled: true,
        },
    ]) : ([
        {
            label: trans('connectors.connectedDevelopmentPlanBreadcrumbs.development.candidate'),
            to: RoutePaths.development(candidate.uuid),
        },
        {
            label: developmentPlan.title,
            disabled: true,
        },
        {
            label: trans('common.evaluation'),
            disabled: true,
        },
    ]);

    return (
        <Breadcrumbs
            crumbs={crumbs}
            className={className}
        />
    );
};

export default DevelopmentPlanEvaluationBreadcrumbs;
